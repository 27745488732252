import { RouteConfig } from 'vue-router'

const routes: RouteConfig[] = [
  {
    path: '/',
    redirect: '/device',
    name: 'main',
    component: () => import('/@/views/index.vue'),
    children: [
      {
        alias: '/',
        name: 'device-view',
        path: 'device/:uuid?',
        props: true,
        component: () => import('/@/views/device-view/index.vue'),
      },
    ],
  },
  {
    path: '/domain',
    name: 'domain',
    component: () => import('/@/views/index.vue'),
    children: [
      {
        name: 'domain-view',
        path: ':id?',
        props: true,
        component: () => import('/@/views/domain-view/index.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('/@/views/login/index.vue'),
  },
  {
    path: '*',
    redirect: '/device',
  },
]

if (import.meta.env.VITE_DEBUG) {
  const testRoute = {
    path: '/testing',
    name: 'testing',
    component: () => import('/@/views/testing/index.vue'),
  }

  routes.splice(1, 0, testRoute)
}

export default routes
