import { updateHexSymbol, convertToArray, empty, convertNumberToHex } from '/@/shared/utils'
import { ObjectType } from '/@/shared/entities/ObjectType'
import { NA } from '/@/shared/constants'
import { StartupData } from '../interfaces'
import { convertHexToNumber } from '/@/shared/utils'
import { SoE } from '/@/shared/entities/StartupItems'
import { CoE } from '/@/shared/entities/StartupItems'
import { Data } from '/@/shared/entities/startup-items/Data'
import { TypeSignature } from '/@/shared/entities/enums/TypeSignature'
import { findByString } from '/@/shared/entities/enums/DataSignature'

const convertIndexToHex = (index: number) => {
  return `#x${index.toString(16)}`
}

const convertSubindexToDecimal = (subIndex: string) => {
  if (typeof subIndex === 'string' && subIndex.startsWith('#x')) {
    return convertHexToNumber(subIndex)
  }

  return Number(subIndex)
}

const extractIndex = (index: any) => {
  if (index instanceof Object) {
    return index._
  }

  if (typeof index === 'string') {
    index = index.toLocaleUpperCase().padStart(4, '0')
  }

  return index
}

const extractSyncManagers = (value: any) => {
  if (!value) {
    return
  }

  return convertToArray(value).map((a: any, index: number) => {
    const props = a.$
    const name = a._ ? a._ : ''

    return {
      index,
      name,
      enable: Boolean(props.enable),
      startAddress: updateHexSymbol(props.startAddress),
      controlByte: updateHexSymbol(props.controlByte),
      defaultSize: props.defaultSize,
    }
  })
}

const extractType = (value: any) => {
  if (!value) {
    return null
  }

  const name = value._ || NA
  const props = value.$ || {}

  let productCode = props.productCode

  if (!isNaN(productCode)) {
    productCode = `#x${Number(productCode).toString(16).padStart(8, '0')}`
  }

  return {
    name,
    productCode,
    revisionNo: props.revisionNo,
    vendorId: props.vendorId,
  }
}

const extractName = (item: any): string => {
  if (Array.isArray(item?.name)) {
    // Some devices have multiple names, we only need the first one
    const name = item?.name.at(0)
    return extractName({ name })
  }

  if (!item.$ && !item.name) {
    return ''
  }

  if (typeof item?.name === 'object' && item.name._ !== undefined) {
    return item.name._
  }

  return item.$?.name ?? item?.name ?? ''
}

const extractGroup = (item: any) => {
  if (!item.$) {
    return ''
  }

  return item.$.group ?? ''
}

const extractVisibility = (item: any) => {
  const visible = item.$?.visible
  return visible !== undefined ? { visible } : null
}

const extractIncluded = (item: any) => {
  const included = item.$?.included
  return included !== undefined ? { included } : null
}

const extractSDOs = (value: any, linkType: string) => {
  if (!value) {
    return null
  }

  value = convertToArray(value)

  return value.map((sdo: any) => {
    const entries = extractEntries(sdo, linkType)

    return {
      type: sdo.type,
      name: extractName(sdo),
      group: extractGroup(sdo),
      entries,
      ...extractIncluded(sdo),
    }
  })
}

const extractPDOs = (value: any, linkType: string) => {
  if (!value) {
    return null
  }

  value = convertToArray(value)

  return value.map((pdo: any) => {
    const entries = extractEntries(pdo, linkType)
    const { fixed, sm, mandatory } = pdo.$ || {}
    const linkedParameters = extractLinks(pdo.link, linkType)
    const excludes = convertToArray(pdo.exclude || [])

    return {
      name: extractName(pdo),
      group: extractGroup(pdo),
      index: extractIndex(pdo.index),
      entries,
      fixed: Boolean(fixed),
      mandatory: Boolean(mandatory),
      excludes,
      ...(sm !== undefined ? { sm } : {}),
      ...(linkedParameters ? { linkedParameters } : {}),
      ...extractVisibility(pdo),
      ...extractIncluded(pdo),
    }
  })
}

const extractEntries = (value: any, linkType: string) => {
  let entries = value.entry

  if (!entries) {
    return null
  }

  entries = convertToArray(entries)

  return entries
    .map((entry: any, i: number) => {
      let dataType = entry.dataType

      if (typeof dataType === 'object') {
        dataType = dataType._
      }

      let dataSignature = TypeSignature.BIT

      if (!empty(dataType)) {
        dataSignature = ObjectType.getSignatureByTypeString(dataType)
      }

      const nextItem = entries[i + i]
      const index = extractIndex(entry.index)

      if (index === 0) {
        return
      }

      const nextItemIsGap = nextItem && extractIndex(nextItem.index) === 0
      const mapping = extractMapping(entry.map, linkType)
      const linkedParameters = extractLinks(entry.link, linkType)
      const bitLength = entry.bitLen / ObjectType.getIndexMultiplierBySignature(dataSignature)

      return {
        index: index,
        subIndex: convertSubindexToDecimal(entry.subIndex ?? 0),
        name: extractName(entry),
        group: extractGroup(entry),
        bitLength,
        type: dataSignature,
        selected: true,
        ...extractIncluded(entry),
        ...extractVisibility(entry),
        ...(linkedParameters !== null ? { linkedParameters } : {}),
        ...(mapping !== null ? { mapping } : {}),
        ...(nextItemIsGap
          ? {
              gapLength: nextItem.bitLen,
              gapName: nextItem.name,
            }
          : null),
      }
    })
    .filter((entry: any) => entry !== undefined)
}

const extractMapping = (mapping: any[], linkType: string) => {
  if (!mapping) {
    return null
  }

  return convertToArray(mapping).map((map) => {
    const index = map.index
    const subIndex = map.subIndex.toString().padStart(2, '0')
    const type =
      map.dataType === undefined //
        ? TypeSignature.BIT
        : ObjectType.getSignatureByTypeString(map.dataType)

    const linkedParameters = extractLinks(map.link, linkType)

    return {
      offset: `${index}:${subIndex}`,
      name: extractName(map),
      group: extractGroup(map),
      bitLength: map.bitLen,
      type,
      ...extractVisibility(map),
      ...extractIncluded(map),
      ...(linkedParameters ? { linkedParameters } : {}),
    }
  })
}

const extractLinks = (paths: string | any, linkType: string) => {
  if (!paths) {
    return null
  }

  return convertToArray(paths).map((path: any) => {
    let channel = 0
    let sim = false
    let pathString = path
    let numberOfElements = 1
    let gain = 1
    let divide = 1
    let gainOffset = 0
    let invert = false

    if (path instanceof Object) {
      const props = path.$

      if (props.hasOwnProperty('index')) {
        channel = props.index
      }

      if (props.hasOwnProperty('sim') || props.hasOwnProperty('simulation')) {
        sim = Boolean(props.simulation || props.sim)
        numberOfElements = Infinity
      }

      if (props.hasOwnProperty('gain')) {
        gain = props.gain
      }

      if (props.hasOwnProperty('divide')) {
        divide = props.divide
      }

      if (props.hasOwnProperty('gainOffset')) {
        gainOffset = props.gainOffset
      }

      if (props.hasOwnProperty('invert')) {
        invert = Boolean(props.invert)
      }

      pathString = path._
    }

    return {
      channel,
      sim,
      gain,
      divide,
      gainOffset,
      invert,
      data: {
        name: pathString.split('/').pop(),
        icon: `tree-${linkType}`,
        path: pathString,
        type: linkType,
        numberOfElements,
        ...extractIncluded(path),
      },
    }
  })
}

const extractDC = (dc: any) => {
  if (!dc) {
    return null
  }

  // Backwards compatibility
  if (!Object.prototype.hasOwnProperty.call(dc?.$ ?? {}, 'included')) {
    dc.$ = {
      included: true,
    }
  }

  if (isNaN(dc.assignActivate)) {
    dc.assignActivate = parseInt(dc.assignActivate.replace('#', 0), 16)
  }

  return {
    assignActivate: dc.assignActivate,
    cycleTS0: dc.cycleTimeSync0,
    cycleTS1: dc.cycleTimeSync1,
    shiftTS0: dc.shiftTimeSync0,
    shiftTS1: dc.shiftTimeSync1,
    ...extractIncluded(dc),
  }
}

const extractDefaultStartupData = (data: any) => {
  let dataObject = new Data(data.data)

  if (data.data instanceof Object) {
    const { _: value, $: props } = data.data

    dataObject = new Data(value, {
      signature: findByString(props.dataType),
      bitLength: parseInt(props.bitLen) ?? 0,
    })
  }

  return {
    ...extractIncluded(data),
    transition: data.transition,
    comment: data.comment,
    timeout: data.timeout,
    data: data.data,
    dataObject,
  }
}

const extractCoE = (data: StartupData) => {
  const completeAccess = data?.$?.completeAccess || false

  return {
    ...extractDefaultStartupData(data),
    ccs: data.ccs,
    index: data.index.toUpperCase(),
    subIndex: data.subIndex,
    completeAccess,
  }
}

const extractSoE = (data: StartupData) => {
  return {
    ...extractDefaultStartupData(data),
    opCode: data.opCode,
    driveNo: data.driveNo,
    idn: data.iDN,
    elements: data.elements,
    attribute: data.attribute,
  }
}

const extractModules = (data: any) => {
  const modules = data?.module || []

  return convertToArray(modules).map((el: any) => {
    const rxPdo = extractPDOs(el.rxPdo, 'input')
    const txPdo = extractPDOs(el.txPdo, 'output')

    let identifier = el.type.$.moduleIdent

    if (!isNaN(identifier)) {
      identifier = convertIndexToHex(identifier)
    }

    const type = {
      identifier,
      name: el.type._,
    }

    let crc32 = null

    if (el.$ !== undefined) {
      crc32 = el.$.crc32
    }

    return {
      crc32,
      name: el.name,
      rxPdo,
      txPdo,
      type,
      get id() {
        return this.type.identifier
      },
    }
  })
}

const increasePdoIndexes = (pdos: any[], increment: any, index: number) => {
  return convertToArray(pdos)
    .filter((pdo: any) => pdo !== null)
    .map((pdo: any) => {
      if (typeof increment === 'string' && increment.toLowerCase().startsWith('#x')) {
        increment = convertHexToNumber(increment)
      }

      const newIndex = (parseInt(pdo.index, 16) + increment * index).toString(16).toUpperCase()

      return {
        ...pdo,
        index: newIndex,
      }
    })
}

const increaseEntryIndexes = (pdos: any[], increment: any, index: number) => {
  return pdos.map((pdo: any) => {
    if (!pdo.entries) {
      return pdo
    }

    pdo.entries = pdo.entries.map((entry: any) => {
      const newIndex = (convertHexToNumber(increment) * index + convertHexToNumber(entry.index)).toString(16)

      return {
        ...entry,
        index: newIndex,
      }
    })

    return pdo
  })
}

const processSlotPdos = (slotModule: any, increment: any, entryIncrement: any, index: number) => {
  /* Increase PDO indexes */
  slotModule.rxPdo = increasePdoIndexes(slotModule.rxPdo, increment, index)
  slotModule.txPdo = increasePdoIndexes(slotModule.txPdo, increment, index)

  /* Increase Entry indexes */
  slotModule.rxPdo = increaseEntryIndexes(slotModule.rxPdo, entryIncrement, index)
  slotModule.txPdo = increaseEntryIndexes(slotModule.txPdo, entryIncrement, index)

  return { ...slotModule }
}

const extractSlots = (device: any, modules: any[]) => {
  const data = device?.slots || { slot: [] }
  const { slotIndexIncrement = 0, slotPdoIncrement = 0 } = data.$ || {}

  return convertToArray(data.slot)
    .map((slot: any, index: number) => {
      const { minInstances, maxInstances } = slot.$
      const moduleIdentifiers = convertToArray(slot.moduleIdent)

      let defaultModule = moduleIdentifiers.find((ident: string | Object) => ident instanceof Object)?._

      if (!defaultModule) {
        return null
      }

      const slotModules = moduleIdentifiers.map((ident: any) => {
        if (ident instanceof Object) {
          ident = ident._
        }

        if (!isNaN(ident)) {
          ident = convertIndexToHex(ident)
        }

        return ident
      })

      if (!isNaN(defaultModule)) {
        defaultModule = convertIndexToHex(defaultModule)
      }

      return {
        minInstances,
        maxInstances,
        defaultModule,
        slotModules,
        index,
      }
    })
    .filter((module: any) => module !== null)
    .map((slot: any) => {
      let defaultModule = modules.find((m: any) => m.id === slot.defaultModule)

      if (!defaultModule) {
        return null
      }

      defaultModule = processSlotPdos({ ...defaultModule }, slotPdoIncrement, slotIndexIncrement, slot.index)

      const slotModules = slot.slotModules
        .map((m: any) => modules.find((a: any) => a.id === m))
        .map((m: any) => processSlotPdos({ ...m }, slotPdoIncrement, slotIndexIncrement, slot.index))

      return { ...slot, defaultModule: { ...defaultModule }, slotModules }
    })
    .filter((module: any) => module !== null)
}

const extractMailbox = (mailbox: any) => {
  if (!mailbox) {
    return
  }

  if (mailbox?.soE?.initCmd) {
    return convertToArray(mailbox.soE.initCmd) //
      .map((startup: any) => new SoE({ ...extractSoE(startup) }))
  }

  if (mailbox?.coE?.initCmd) {
    return convertToArray(mailbox.coE.initCmd) //
      .map((startup: any) => new CoE({ ...extractCoE(startup) }))
  }
}

export {
  extractSyncManagers,
  extractLinks,
  extractType,
  extractPDOs,
  extractName,
  extractDC,
  extractCoE,
  extractSoE,
  extractSDOs,
  extractIncluded,
  extractModules,
  extractSlots,
  extractMailbox,
}
