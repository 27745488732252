import { createHttpInstance } from '../http'
import { portalUrl } from '../utils'
import { EthercatDevice } from '../entities/EthercatDevice'

export const http = createHttpInstance(portalUrl('/api'), 5000, {
  withCredentials: true,
})

export const accounts = {
  me: <T = any>() => http.get<{ data: T }>('/accounts/me'),
}

export type EsiSearchResultType = {
  vendorID: string
  revisionNumber: string
  productCode: string
  esis: {
    name: string
    url: string
  }[]
  xml?: string
}

export const esi = {
  search: (devices: EthercatDevice[]) => {
    const payload = devices.map((device) => {
      return {
        vendorID: device.vendorId,
        productCode: device.type.productCode,
        revisionNumber: device.type.revisionNo,
      }
    })

    return http.post<{ data: EsiSearchResultType[] }>('/esi/search', {
      devices: payload,
    })
  },
  download: (esis: { name: string; url: string }[]) => {
    const esi = esis[0]
    return http.get(esi.url)
  },
}
