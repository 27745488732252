import { computed, ref } from 'vue'
import { getAppModule } from '/@/store/helpers'
import { accounts as apiAccounts } from '/@/shared/api'
import router from '/@/router'
export type AccountData = {
  name: string
  email: string
  avatar?: string
}

const isInitialized = ref(false)
const appModule = getAppModule()
const fetchPromise = ref<Promise<void> | null>(null)

const fetchAccount = () => {
  const request = apiAccounts
    .me<AccountData>()
    .then((result) => {
      appModule.setAccount(result.data.data)
    })
    .catch((error) => {
      if (error.response.status === 401) {
        appModule.setAccount(null)
        router.push('/login').catch(() => {})
      }
    })
    .finally(() => {
      fetchPromise.value = null
    })

  fetchPromise.value = request
  return request
}

const initialize = () => {
  if (isInitialized.value) {
    return
  }

  if (!appModule.isLoggedIn) {
    fetchAccount()
  }

  window.addEventListener('visibilitychange', (event: Event) => {
    if (document.visibilityState === 'visible') {
      fetchAccount()
    }
  })

  isInitialized.value = true
}

initialize()

export function useAccount() {
  return {
    account: computed(() => appModule.account),
    isLoggedIn: computed(() => appModule.isLoggedIn),
    hasAvatar: computed(() => !!appModule.account?.avatar),
    fetchAccount,
    fetchPromise,
  }
}
