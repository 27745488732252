import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { useAccount } from '/@/shared/composables/account'

const { isLoggedIn, fetchPromise } = useAccount()
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'active',
})

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)

  if (fetchPromise.value) {
    await fetchPromise.value
  }

  if (authRequired && !isLoggedIn.value) {
    // TODO: Save the current route to redirect to after login
    return next('/login')
  }

  next()
})

export default router
